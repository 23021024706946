export const lightTheme = {
  background: '#BACCF2',
  color: '#251470',
  accentColor: '#0A1F43',
  chronoTheme: {
    cardBgColor: '#A9B9DC',
    cardForeColor: 'black',
    titleColor: 'white',
  },
  timelineLineColor: '#0D2E4D',
  cardBackground: '#C5D8FF',
  cardFooterBackground: '#A9B9DC',
  cardBorderColor: '#0A1F43',
  navbarTheme: {
    linkColor: '#dedede',
    linkHoverColor: '#fefefe',
    linkActiveColor: '#fefefe',
  },
  bsPrimaryVariant: 'white',
  bsSecondaryVariant: 'dark',
  socialIconBgColor: '#121212',
};

export const darkTheme = {
  background: '#0E0D0D',
  color: '#eee',
  accentColor: '#f2cc58',
  chronoTheme: {
    cardBgColor: '#1B1B1B',
    cardForeColor: '#eee',
    titleColor: 'black',
  },
  timelineLineColor: '#444',
  cardBackground: '#060606',
  cardFooterBackground: '#181818',
  cardBorderColor: '#ffffff20',
  navbarTheme: {
    linkColor: '#dedede',
    linkHoverColor: '#fefefe',
    linkActiveColor: '#fefefe',
  },
  bsPrimaryVariant: 'dark',
  bsSecondaryVariant: 'light',
  socialIconBgColor: '#fefefe',
};
